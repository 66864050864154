import { GLTFExporter, GLTFExporterOptions } from 'three/examples/jsm/exporters/GLTFExporter.js';

const exporter = new GLTFExporter();

class Exporter {
    scene: any;

    constructor(scene) {
        this.scene = scene;
    }

    export() {
        const options: GLTFExporterOptions = {
            binary: false,        // Set to true for .glb
            embedImages: true,    // Embed images for .gltf
            maxTextureSize: Infinity
        };

        exporter.parse(
            this.scene,
            function (result) {
                

                // 'result' is a JSON object containing the .gltf data
                const output = JSON.stringify(result, null, 2);
                saveString(output, 'model.gltf');
            }, (e) => { console.log("Export Error",e) }, options
        );
    }
}

function saveString(text, filename) {
    const blob = new Blob([text], { type: 'text/plain' });
    saveBlob(blob, filename);
}

function saveBlob(blob, filename) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}


export { Exporter }