import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContractOwnerView from '../views/ContractOwnerView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    //{
    //    path: '/contract-owner',
    //    name: 'owner',
    //    component: ContractOwnerView
    //},
    //{
    //    path: '/contract/:contractId',
    //    name: 'contract',
    //    // route level code-splitting
    //    // this generates a separate chunk (about.[hash].js) for this route
    //    // which is lazy-loaded when the route is visited.
    //    component: () => import(/* webpackChunkName: "contract" */ '../views/ContractView.vue')
    //},
    //{
    //    path: '/orders/:contractId',
    //    name: 'orders',
    //    component: () => import(/* webpackChunkName: "orders" */ '../views/OrderView.vue')
    //},
    //{
    //    path: '/tokens/image/:fileName',
    //    name: 'tokenImage',
    //    component: () => import(/* webpackChunkName: "tokenImage" */ '../views/TokenImageView.vue')
    //},
    //{
    //    path: '/demo/:contractId',
    //    name: 'demo',
    //    component: () => import(/* webpackChunkName: "contract" */ '../views/DemoView.vue')
    //},
    {
        path: '/auth/:chipAddress/:uniqueId',
        name: 'auth',
        component: () => import(/* webpackChunkName: "auth" */ '../views/AuthView.vue')
    },
    {
        path: '/create-model',
        name: 'createModel',
        component: () => import(/* webpackChunkName: "createModel" */ '../views/CreateModelView.vue')
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
