
import { getTemplateData } from "@/services/templateServices"
import { ethers } from "ethers";


export class ProductTemplate {
    //[key: string]: string | number;
    name!: string;
    description!: string;
    templateUrl!: string;
    templateMarkup: string;
    templateStyles!: string;
    contractAddress!: string;
    onChain!: boolean;
    id!: bigint;
    quantityMinted!: number;
    amountPer!: number;
    amountTotal!: number;
    cost!: bigint;
    costEth!: string;
    productType!: number;



    constructor(markup:string) {        
        this.templateMarkup = markup
        this.pullDetailsFromMarkup()
    }

    public static fromMarkup(markup: string): ProductTemplate {
        const t = new ProductTemplate(markup);
        return t;
    }

    public pullDetailsFromMarkup() {
        if (this.templateMarkup.length) {
            const el = document.createElement('span')
            el.innerHTML = this.templateMarkup
            const svg: HTMLElement | null = el.firstChild as HTMLElement

            if (svg.getElementsByTagName("title").length) {
                this.name = svg.getElementsByTagName("title")[0].innerHTML
            } else this.name = "Template"

            if (svg.getElementsByTagName("desc").length) {
                this.description = svg.getElementsByTagName("desc")[0].innerHTML
            }

            if (svg.getElementsByTagName("style").length) {
                this.templateStyles = "<style>" + svg.getElementsByTagName("style")[0].innerHTML + "</style>"
            }
        }
    }

    public static async fromContractTemplate(ct: any): Promise<ProductTemplate> {

        const markup = await getTemplateData(ct.u);
        const template = ProductTemplate.fromMarkup(markup);
        template.templateUrl = ct.u

        let b = BigInt(ct.p);
        const p1 = b >> 192n;
        console.log("product type", p1);
        template.productType = Number(p1)

        b = b - (p1 * 2n ** 192n);
        const p2 = b >> 128n;
        console.log("quantity minted", p2);
        template.quantityMinted = Number(p2)

        b = b - (p2 * 2n ** 128n);
        const p3 = b >> 64n;
        console.log("total allowed", p3);
        template.amountTotal = Number(p3)

        b = b - (p3 * 2n ** 64n);
        const p4 = b;
        console.log("allowed per token", p4);
        template.amountPer = Number(p4)

        template.cost = ct.c
        template.costEth = ethers.utils.formatEther(ct.c)
        template.onChain = true
        template.id = ct.id

        return template;
    }


}