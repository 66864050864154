import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

function createControls(camera, canvas) {
    const controls = new OrbitControls(camera, canvas);

    controls.enableDamping = true;
    //controls.rotateSpeed = 10;
    //controls.enablePan = false;

    controls.maxDistance = 10;
    controls.minDistance = 1;

    //controls.maxZoom = 10;
    //controls.minZoom = 1;
    //controls.autoRotate = true;
    //controls.autoRotateSpeed = 8;
    //controls.enableZoom = false;

    // forward controls.update to our custom .tick method
    //controls.tick = () => controls.update();    
    return controls;
}

export { createControls };