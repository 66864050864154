
import alchemy from "@/services/alchemyServices"
import { ethers } from "ethers"
import { parseEther } from "viem"
import { abi } from "../assets/contracts/NFSK8NFT.json"
import { abi as linkAbi } from "../assets/contracts/AggregatorV3Interface.json"
import { ProductTemplate } from "@/classes/productTemplate"
import { ethereumClient, publicClient, wagmiConfig } from "@/services/walletServices"
import { prepareWriteContract, writeContract, watchContractEvent, waitForTransaction, watchWalletClient, watchPublicClient, ConnectorAlreadyConnectedError, Connector, getWalletClient } from '@wagmi/core'
import { ConnectorEvents } from '@wagmi/connectors';
import { Token } from "../classes/token"

export default  {

    async getContractTemplates(nftContract: string): Promise<ProductTemplate[]> {
        console.log("GetTemplates for ", nftContract)

        console.log("Contract abi: ", abi)

        const nfSwagAddress = process.env.VUE_APP_NFSWAG_CONTRACT_ADDRESS as string // "0xcb87C84902A16D8B4302f1D8110dd7ED6b7b415a"
        const alchemyProvider = await alchemy.config.getProvider()
        const nfSwagContract = new ethers.Contract(nfSwagAddress, abi, alchemyProvider)
        const productTemplates = [] as ProductTemplate[]

        try { // this try catch can be removed once the contract doesn't error out on unregistered contracts
            const contractTempaltes = JSON.parse(await nfSwagContract.getContractTemplates(nftContract))
            console.log("Templates: ", contractTempaltes)

            for (const t of contractTempaltes) {
                productTemplates.push(await ProductTemplate.fromContractTemplate(t))
            }

        } catch (ex) {
            console.log(ex)
        }
        return productTemplates


    },

    async mintTemplate(template: ProductTemplate): Promise<string> {

        console.log("send mint template transaction")
        const nfSwagAddress = process.env.VUE_APP_NFSWAG_CONTRACT_ADDRESS as `0x${string}`

        const templateUri = "https://bondcarr.com/assets/BPNT_Template1.svg";
        const r = [{ amount: 5000000000000000, receiver: ethereumClient.getAccount().address }];

        const config = await prepareWriteContract({
            address: nfSwagAddress,
            abi: abi,
            functionName: "registerTemplate",
            args: [template.contractAddress, templateUri, template.amountPer, template.amountTotal, r, true, 1],
            chainId: await ethereumClient.getAccount().connector?.getChainId()
        })
        const { hash } = await writeContract(config.request)
        console.log("result: ", hash)
        return hash;

    },

    async mintProductOrder(template: ProductTemplate, token: Token, price: number): Promise<string> {

        console.log("send mint order transaction")

        //NFSWAGContract.safeMint(addr1.address, tokenUri, templateId, 0, nftContractAddress,
        //    { value: ethers.utils.parseEther("0.5") });

        //generate product preview
        const productPreviewUrl = ""

        //post json for order 
        const jsonUrl = "https://bondcarr.com/tokens/1"

        //const provider = await alchemy.config.getProvider();
        const nfSwagAddress = process.env.VUE_APP_NFSWAG_CONTRACT_ADDRESS as `0x${string}`
        const accountChain = await ethereumClient.getAccount().connector?.getChainId()
        const accountAddress = ethereumClient.getAccount().address
        const nftContractAddress = token.contract?.address

        const config = await prepareWriteContract({
            address: nfSwagAddress,
            abi: abi,
            functionName: "safeMint",
            args: [accountAddress, jsonUrl, template.id, token.tokenId, nftContractAddress],
            chainId: accountChain,           
            value: parseEther(`${price}`),
        })
        //const unwatch = watchContractEvent(
        //    {
        //        address: nfSwagAddress,
        //        abi: abi,
        //        eventName: 'Transfer',
        //        chainId: accountChain,
        //    },
        //    (adr0, to, tokenId) => {
        //        console.log('Trashfer watch', adr0)
        //        console.log('Trashfer watch to', to)
        //        console.log('Trashfer watch tokenId', tokenId)
        //    },
        //)
        try {
            const { hash } = await writeContract(config.request)

            const data = await waitForTransaction({
                chainId: accountChain,
                hash,
            })
            console.log("order minting result hash: ", hash)
            console.log("order minting result data: ", data)

            return hash
        } catch (ex) {
            return ''
        }
    },
    async getContractWatch(callback: any) {
        const nfSwagAddress = process.env.VUE_APP_NFSWAG_CONTRACT_ADDRESS as `0x${string}`
        const accountChain = await ethereumClient.getAccount().connector?.getChainId()
        return watchContractEvent(
            {
                address: nfSwagAddress,
                abi: abi,
                eventName: 'Transfer',
                chainId: accountChain,
            }, callback
            //(adr0, to, tokenId) => {
            //    console.log('Trashfer watch', adr0)
            //    console.log('Trashfer watch to', to)
            //    console.log('Trashfer watch tokenId', tokenId)
            //},
        )
    },
    async getWalletWatch(callback: any) {
        console.log("setting wallet watch")
        //const nfSwagAddress = process.env.VUE_APP_NFSWAG_CONTRACT_ADDRESS as `0x${string}`
        const accountChain = await ethereumClient.getAccount().connector?.getChainId()
        console.log("watching chain:", accountChain)

        const w = await getWalletClient()
        console.log("watching wallet chain:", w?.chain)
        console.log("wallet: ", wagmiConfig.status)
        //return wagmiConfig.connector?.on("change", (walletClient: any) => console.log('Wallet watch', walletClient))
        return wagmiConfig.connector?.on("message", (walletClient: any) => console.log('Wallet watch', walletClient))

        //return watchWalletClient(
        //    {
        //        //chainId: accountChain,
        //    },
        //    (walletClient: any) => console.log('Wallet watch', walletClient),
        //)

        //return watchContractEvent(
        //    {
        //        address: nfSwagAddress,
        //        abi: abi,
        //        eventName: 'Transfer',
        //        chainId: accountChain,
        //    }, callback
        //    //(adr0, to, tokenId) => {
        //    //    console.log('Trashfer watch', adr0)
        //    //    console.log('Trashfer watch to', to)
        //    //    console.log('Trashfer watch tokenId', tokenId)
        //    //},
        //)
    },
    async getEthUsd(): Promise<number> {
        console.log("getting ethusd price")
        const linkContractAddress = "0x694AA1769357215DE4FAC081bf1f309aDC325306"

        const alchemyProvider = await alchemy.config.getProvider()
        const linkContract = new ethers.Contract(linkContractAddress, linkAbi, alchemyProvider)

        try { 
            const roundData = await linkContract.latestRoundData()
            console.log("roundData: ", roundData)
            const decimals = await linkContract.decimals()

            return Number((roundData.answer.toString() / Math.pow(10, decimals)).toFixed(2))

        } catch (ex) {
            console.log(ex)
            return 0
        }


    }

}