
import { AnimationClip, Quaternion, QuaternionKeyframeTrack, Vector3, AnimationMixer, LoopOnce } from "three";
import * as THREE from 'three';

function deckDetailAnimation(deck) {

    const wiggleAmount = Math.PI / 8;
    // set up rotation about y axis
    const yAxis = new Vector3(0, 1, 0);
    const qInitial = new Quaternion().setFromAxisAngle(yAxis, 0);
    const qMiddle1 = new Quaternion().setFromAxisAngle(yAxis, Math.PI / 2);
    const qMiddle2 = new Quaternion().setFromAxisAngle(yAxis, Math.PI);
    const qMiddle3 = new Quaternion().setFromAxisAngle(yAxis, Math.PI * 1.5);
    const qFinal = new Quaternion().setFromAxisAngle(yAxis, Math.PI * 2);
    const qWiggle1 = new Quaternion().setFromAxisAngle(yAxis, wiggleAmount);
    const qWiggle2 = new Quaternion().setFromAxisAngle(yAxis, -wiggleAmount);

    const initialWait = 0.5;
    const rotationKF = new QuaternionKeyframeTrack('.quaternion',
        [
            0,
            initialWait,
            initialWait + 0.2,
            initialWait + 0.4,
            initialWait + 0.5,
            initialWait + 0.6,
            initialWait + 0.8,
            initialWait + 1,
            //initialWait + 0.45,
            //initialWait + 0.5,
            initialWait + 1.2,
        ],
        [
            qInitial.x, qInitial.y, qInitial.z, qInitial.w,
            qInitial.x, qInitial.y, qInitial.z, qInitial.w,
            qMiddle1.x, qMiddle1.y, qMiddle1.z, qMiddle1.w,
            qMiddle2.x, qMiddle2.y, qMiddle2.z, qMiddle2.w,
            qMiddle3.x, qMiddle3.y, qMiddle3.z, qMiddle3.w,
            qFinal.x, qFinal.y, qFinal.z, qFinal.w,
            qWiggle1.x, qWiggle1.y, qWiggle1.z, qWiggle1.w,
            qWiggle2.x, qWiggle2.y, qWiggle2.z, qWiggle2.w,
            //qWiggle1.x, qWiggle1.y, qWiggle1.z, qWiggle1.w,
            //qWiggle2.x, qWiggle2.y, qWiggle2.z, qWiggle2.w,
            qFinal.x, qFinal.y, qFinal.z, qFinal.w,
        ]

    );


    const moveBlinkClip = new AnimationClip("move-n-blink", -1, [
        rotationKF
    ]);

    const mixer: THREE.AnimationMixer = new AnimationMixer(deck);
    const action = mixer.clipAction(moveBlinkClip);
    action.setLoop(LoopOnce, 1);
    action.play();

    return mixer;
}

export { deckDetailAnimation }