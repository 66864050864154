import { WebGLRenderer, SRGBColorSpace } from 'three';

function createRenderer() {
    const renderer = new WebGLRenderer({ antialias: true, powerPreference: "high-performance", precision: "highp", alpha: true });

    renderer.shadowMap.enabled = true;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.outputColorSpace = SRGBColorSpace;

    return renderer;
}

export { createRenderer };