import { SpotLight, AmbientLight, PointLight, DirectionalLight } from 'three';

function createLights() {
   
    const ambientLight = new AmbientLight(0xf0f0f0, 2.2);

    const topSheetLight = new DirectionalLight(0xffffff, 1.5);
    topSheetLight.position.set(-1, 0, 3);
    //mainLight.angle = Math.PI * 0.2;
    //mainLight.decay = 0;
    //topSheetLight.castShadow = true;
    //mainLight.shadow.camera.near = 0.025;
    //mainLight.shadow.camera.far = 20;
    //mainLight.shadow.bias = 0.01;


    //mainLight.shadow.mapSize.height = 200;
    //mainLight.shadow.mapSize.width = 200;

    //mainLight.shadow.mapSize.width = 50024;
    //mainLight.shadow.mapSize.height = 50024;

    const baseLight = new SpotLight(0xffffff, 6);
    //backLight.position.set(1, 0, -3);
    baseLight.position.set(0.6, 1.2, -0.45);
    baseLight.angle = Math.PI * 0.2;
    baseLight.decay = 2;
    //baseLight.castShadow = true;
    //backLight.shadow.camera.near = 1;
    //backLight.shadow.camera.far = 20;
    //backLight.shadow.radius = 4;
    ////backLight.shadow.bias = 0.01;
    //backLight.shadow.mapSize.width = 50024;
    //backLight.shadow.mapSize.height = 50024;

    //const baseLight2 = new SpotLight(0xf0f6ff, 0);
    ////backLight.position.set(1, 0, -3);
    //baseLight2.position.set(-0.5, -0.5, -0.2);
    //baseLight2.angle = Math.PI * 0.2;
    //baseLight2.decay = 0.5;
    //baseLight2.castShadow = true;
    //backLight.shadow.camera.near = 1;
    //backLight.shadow.camera.far = 20;
    //backLight.shadow.radius = 4;
    ////backLight.shadow.bias = 0.01;
    //backLight.shadow.mapSize.width = 50024;
    //backLight.shadow.mapSize.height = 50024;

    return { ambientLight, topSheetLight, baseLight };
}

export { createLights };