import { Mesh } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

async function loadDeck(modelUrl) {
    const loader = new GLTFLoader();

    const [deckModel] = await Promise.all([
        loader.loadAsync(modelUrl),
    ]);

    console.log('DeckModel ', deckModel);

    const deck = deckModel.scene;
    deck.name = "threeDeck"
    //setShadowCasting(deck);
    deck.children[0]?.position.set(0, 0, 0);

    return deck;
}

function setShadowCasting(deck) {
    deck.traverse(function (node) {
        if (node instanceof Mesh) {
            node.castShadow = true;
        }
    });
    deck.castShadow = true;
}

export { loadDeck };