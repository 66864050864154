import * as THREE from 'three';
import { createCamera } from './camera';
import { createLights } from './lights';
import { createScene } from './scene';
import { createControls } from './controls';
import { createRenderer } from './renderer';
import { Loop } from './loop';
import { Resizer } from './resizer';
import { loadDeck } from './modelLoader';
import { loadTexture } from './textureLoader';
import { deckDetailAnimation } from './animations'
/*import { CameraHelper, Texture, WebGLCapabilities } from 'three';*/
import WebGL from 'three/examples/jsm/capabilities/WebGL';
import { Exporter } from './exporter';

let camera;
let renderer;
let scene;
let loop;
let controls;
let deck;
let sceneProductInfo;


class World {
    constructor(container, statusEventHandler, isLoadedHandler) {
        camera = createCamera();
        renderer = createRenderer();
        scene = createScene();
        loop = new Loop(camera, scene, renderer);
        container.append(renderer.domElement);
        

        controls = createControls(camera, renderer.domElement);
        const { ambientLight, topSheetLight, baseLight } = createLights();

        //document.querySelector('#canvasContainer').addEventListener('mousedown', (evt) => {
        //    console.log("Mouse Down", evt);
        //    mainLight.castShadow = false;
        //    backLight.castShadow = false;
        //});

        const canvasContainer = document.querySelector('#canvasContainer');
        if (canvasContainer != null) { 
            canvasContainer.addEventListener('mouseup', (evt) => {
                //resets camera target after panning.
                if (controls.target.x != 0 || controls.target.y != 0 || controls.target.z != 0) {
                    controls.target.x = 0;
                    controls.target.y = 0;
                    controls.target.z = 0;
                }
            });
        }

       
        loop.updatables.push(controls);
        scene.add(ambientLight, topSheetLight, baseLight);
        //scene.add(new CameraHelper(mainLight.shadow.camera));
        //scene.add(new CameraHelper(baseLight.shadow.camera));

        const resizer = new Resizer(container, camera, renderer);

        window.addEventListener("statusUpdate", statusEventHandler);
        window.addEventListener("deckIsLoaded", isLoadedHandler);
    }

    async init(productInfo) {

        console.log("Loading Deck Info: ", productInfo);

        if (!WebGL.isWebGLAvailable()) {
            return false;
        }

        let newDeck = true;

        if (!deck) {
            deck = await loadDeck(productInfo.model);
        } else if (sceneProductInfo.model != productInfo.model || (sceneProductInfo.grip && !productInfo.grip)) {
            scene.remove(deck);
            deck = await loadDeck(productInfo.model)
        } else {
            newDeck = false;
        }

        if (productInfo.image && (newDeck || sceneProductInfo.image != productInfo.image)) {
            const baseTexture = await loadTexture(productInfo.image, productInfo.width, productInfo.height, !productInfo.imageIsThree, false);
            await this.setBaseImage(baseTexture, productInfo.baseType);
        }

        if (productInfo.grip && (newDeck || sceneProductInfo.grip != productInfo.grip)) {
            const topTexture = await loadTexture(productInfo.grip, productInfo.width * 2, productInfo.height * 2, !productInfo.gripIsThree, true);
            await this.setTopsheetImage(topTexture);
        }

        this.adjustScene(productInfo);

        scene.add(deck);
        sceneProductInfo = productInfo;
        window.dispatchEvent(new Event("deckIsLoaded"));

        console.log("Deck added to scene");


        if (productInfo.autoRotate == true) {
            controls.autoRotate = true;
            controls.autoRotateSpeed = 10;
        } else {
            //if (productInfo.location != "designer") {
            const deckAnimation = deckDetailAnimation(deck);
            loop.updatables.push(deckAnimation);
            //}
        }
        return true;
    }

    getMaterial(objectName): THREE.MeshStandardMaterial {

        let exMaterial!: THREE.MeshStandardMaterial;
        deck.traverse(function (object) {
            if (object.material && object.material.name == objectName) {
                console.log("texture material found", object.material)
                exMaterial = object.material;
            }
        });
        return exMaterial;
    }

    async setTexture(objectName: string, textureImage: THREE.Texture, roughness = 0.6, metalness = 0, isTopSheet = false) {
        
        const exMaterial: THREE.MeshStandardMaterial = this.getMaterial(objectName);

        if (exMaterial != null) {
            exMaterial.roughness = roughness;
            exMaterial.metalness = metalness;

            const exMap = exMaterial.map as THREE.Texture
            exMap.image = textureImage.image;
            exMap.needsUpdate = true;
        }
    }

    async setBaseImage(baseImage: THREE.Texture, baseType = 0) {
        let roughness = 0.6;
        let metalness = 0;
        if (baseType == 1) { //natural
            roughness = 0.7;
        } else if (baseType == 2) { //silver
            roughness = 0.5;
            metalness = 0.3;
        } else if (baseType == 3) { //holo
            roughness = 0.5;
            metalness = 0.4;
        }
        console.log("roughness: ", roughness);
        console.log("metalness: ", metalness);
        await this.setTexture("deck_graphic", baseImage, roughness, metalness)
    }

    async setTopsheetImage(baseImage: THREE.Texture) {
        await this.setTexture("grip_graphic", baseImage, 1, 0, true)
    }

    adjustScene(productInfo) {
        //if (productInfo.location != "designer") {
        //    //const shadowPlane = scene.getObjectByName("shadowPlane");
        //    if (productInfo.height > 1000) {
        //        //shadowPlane.position.y = -0.52;
        //        camera.position.set(0, 0.25, -7);
        //        this.render();
        //    } else {
        //        //shadowPlane.position.y = -0.45;
        //        camera.position.set(0, 0.25, -6);
        //        this.render();
        //    }
        //}
        controls.reset();
        this.render();
    }

    exportModel(){
        const exporter = new Exporter(scene);
        exporter.export();
    }

    render() {
        renderer.render(scene, camera);
    }

    start() {
        loop.start();
    }

    stop() {
        loop.stop();
    }
}

export { World };