
export function mirrorImage(ctx, image, x = 0, y = 0, horizontal = false, vertical = false) {
    ctx.save();  // save the current canvas state
    ctx.setTransform(
        horizontal ? -1 : 1, 0, // set the direction of x axis
        0, vertical ? -1 : 1,   // set the direction of y axis
        (horizontal ? image.width : 0), // set the x origin
        (vertical ? image.height : 0)   // set the y origin
    );
    ctx.drawImage(image, -x, y);
    ctx.restore(); // restore the state as it was when this function was called
}


export const loadImage = async function (url: string) {
    const $img = document.createElement('img')
    $img.src = url
    return new Promise((resolve, reject) => {
        $img.onload = () => resolve($img)
        $img.onerror = reject
    })
}

export function setImageCanvasSize(img: HTMLImageElement, canvasWidth, canvasHeight, flipImage) {
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    console.log("Canvas Width:", canvas.width);
    console.log("Image Width:", img.width);

    if (canvasContext != null) {
        const x = (canvasWidth - img.width) / 2;
        const y = (canvasHeight - img.height) / 2;

        if (flipImage) {
            mirrorImage(canvasContext, img, x, y, false, false);
        } else {
            canvasContext.drawImage(img, x, y);
        }

        canvasContext.restore();
        canvas.remove()
    }

    return canvas.toDataURL('image/jpeg', 1);

}

