import * as THREE from 'three';
import { TextureLoader } from "three";
import { loadImage, mirrorImage, setImageCanvasSize } from "./imageHelper";

async function loadTexture(imageUrl, productWidth, productHeight, flipClip, isGrip) {
    //const imageData = await prepFor3d(imageUrl, productWidth, productHeight) as THREE.Texture;
    if (flipClip || isGrip) {
        return await prepFor3d(imageUrl, productWidth, productHeight) as THREE.Texture;
    }
    else {        
        return await getTextureFromImage(imageUrl) as THREE.Texture;
    }
}

async function getTextureFromImage(textureImage) {

    //promise is needed to make sure texture is fully loaded into threejs before applying image texture to model
    const getTexture = () => new Promise((res, rej) => {
        const loader = new TextureLoader();
        loader.load(textureImage, (texture) => res(texture));
    });
    return await getTexture() as THREE.Texture;
}

async function prepFor3d(url: string, productWidth, productHeight) {
    console.log("RUNNING PREP");
    const img = await loadImage(url) as HTMLImageElement;
    const imgData = setImageCanvasSize(img, productWidth, productHeight, false);
    return await getTextureFromImage(imgData);
}


export { loadTexture };