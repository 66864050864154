import { PerspectiveCamera } from 'three';

function createCamera() {
    const camera = new PerspectiveCamera(10, 0.6 , 0.1, 200);

    camera.position.set(0, 0.25, -6);

    return camera;
}

export { createCamera };