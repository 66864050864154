import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { configureChains, createConfig } from '@wagmi/core'
import { sepolia } from '@wagmi/core/chains'

const chains = [sepolia]
const projectId:string = process.env.VUE_APP_WC_PROJECTID as string
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
//const wagmiClient = createClient({
//    autoConnect: true,
//    connectors: w3mConnectors({ projectId, version: 1, chains }),
//    provider
//})

const wagmiConfig = createConfig({
    autoConnect: false,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    publicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({ projectId }, ethereumClient)
export { ethereumClient, web3modal, publicClient, wagmiConfig }