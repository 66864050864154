
import { Network, Alchemy } from "alchemy-sdk"

const alchemy = new Alchemy({
    //apiKey: process.env.VUE_APP_ALCHEMY_KEY,
    //network: Network.ETH_MAINNET,
    apiKey: process.env.VUE_APP_ALCHEMY_KEY,
    //network: Network.BASE_SEPOLIA,
    network: Network.MATIC_AMOY,
    //https://base-sepolia.g.alchemy.com/v2/7O5IHv1E6CQzdpDX9KcMEx_pG-Rob3CP
});
export default alchemy