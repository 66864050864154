import { defineStore } from 'pinia'
import { Token } from '@/classes/token'
import { Contract } from '@/classes/contract'
import { ProductTemplate } from '@/classes/productTemplate'
import { countries } from "../assets/data/countries.json"
import nfswagServices from '../services/nfswagServices'

export const useAppStore = defineStore({
    id: 'app',
    state: () => ({
        selectedToken: {} as Token,
        selectedTemplate: {} as ProductTemplate,
        showProductMint: false,
        newTemplate: {} as ProductTemplate,
        selectedContract: {} as Contract,
        showNewTemplate: false,
        localTemplates: [] as ProductTemplate[],
        testTokenId: "0",
        showMintTemplate: false,
        shipCountries: countries,
        ethUsdPrice: 0,
        contractName: "",
        progressHappening: false,
        progressDescription: "",
        problemExists: false,
        problemDescription: "",
        shippingCost: 15,
        selectedProductImage: "",
    }),
    //persist: true,
    actions: {
        openProductMint(t: Token, pt: ProductTemplate) {
            this.selectedToken = t
            this.selectedTemplate = pt
            this.showProductMint = true
        },
        closeProductMint() {
            
            this.showProductMint = false
        },
        openNewTemplate(c: Contract) {
            this.selectedContract = c
            this.newTemplate = {} as ProductTemplate
            this.showNewTemplate = true
        },
        closeNewTemplate() {
            console.log("closing new template form")
            this.selectedContract = {} as Contract
            this.showNewTemplate = false
        },
        openEditTemplate(c: Contract, t: ProductTemplate) {
            this.selectedContract = c
            this.newTemplate = t
            this.showNewTemplate = true
        },
        closeEditTemplate() {
            console.log("closing edit template form")
            this.selectedContract = {} as Contract
            this.showNewTemplate = false
        },
        openMintTemplate(c: Contract, t: ProductTemplate) {
            this.selectedContract = c
            this.newTemplate = t
            this.showMintTemplate = true
        },
        closeMintTemplate() {
            console.log("closing mint template form")
            this.selectedContract = {} as Contract
            this.newTemplate = {} as ProductTemplate
            this.showMintTemplate = false
        },
        //Todo: set refresh policy
        async fetchEthPrice() {
            console.log("getting eth price")
            this.ethUsdPrice = await nfswagServices.getEthUsd() 
        },
        setProgressOn(description: string) {
            this.progressDescription = description
            this.progressHappening = true
        },
        setProgressOff() {
            this.progressDescription = ""
            this.progressHappening = false
        },
        setProblem(description: string) {
            this.problemDescription = description
            this.problemExists = true
        },
        clearProblem() {
            this.problemDescription = ""
            this.problemExists = false
        },
        resetApp() {
            this.setProgressOff()
            this.clearProblem()
            this.contractName = ""

        }


    }
})