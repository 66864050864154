import { ProductTemplate } from "@/classes/productTemplate"

const Templates: ProductTemplate[] = [];

//Templates.push(ProductTemplate.fromMarkup("Token Version",
//`<svg xmlns="http://www.w3.org/2000/svg" width="180" height="660" viewBox="0 0 2700 9900" class="template-1 deck-template">
//        <rect v-if="this.attributes.background.value == 'Light Green'" style="fill: #9AE2C6;" x="0" y="0" width="2700" height="9900" />
//        <rect v-else-if="this.attributes.background.value == 'Sandy Yellow'" style="fill: #C4BF89;" x="0" y="0" width="2700" height="9900" />
//        <rect v-else style="fill: #ff0000;" x="0" y="0" width="2700" height="9900" />
//        <rect style="fill: #000000;" x="196" y="4004" width="2309" height="2309" />
//        <!--<path class="cls-3" d="M298.924,4107.92H2400.08V6209.08H298.924V4107.92Z" />-->
//        <image id="nftImage" x="202" y="4010" width="2297" height="2297" :xlink:href="this.imageUrl" />
//        <text id="TOKEN_ID" data-name="TOKEN ID" style="font-size: 60px;text-anchor: middle;font-family: Roboto;font-weight: 500;" transform="translate(1356.24 3875.82) scale(6.868)"><tspan x="0">
//            {{this.tokenId}}
//        </tspan></text>
//        <image id="nftImage" x="-525" y="-75" width="3750" height="10050" xlink:href="https://www.boardpusher.com/design/decktemplates/8_25_mask_build_BP_standard_28.png" />
//    </svg>`
//))


//Templates.push(ProductTemplate.fromMarkup("Token Version",
//    `<svg xmlns="http://www.w3.org/2000/svg" width="180" height="660" viewBox="0 0 2700 9900" class="template-1 deck-template">
//        <rect v-if="this.attributes.background.value == 'Light Green'" style="fill:#8CDFBE;" x="0" y="0" width="2700" height="9900" />
//        <rect v-else-if="this.attributes.background.value == 'Sandy Yellow'" style="fill:#BDB779;" x="0" y="0" width="2700" height="9900" />
//        <rect v-else style="fill: #ff0000;" x="0" y="0" width="2700" height="9900" />
//        <image id="nftImage" x="202" y="4010" width="2297" height="2297" :xlink:href="this.imageUrl" />
//<g id="Text_Layer" data-name="Text Layer">
//    <text x="50%" y="1500" style="text-anchor:middle;text-align:center;fill: #000; font-family: Bahnschrift, Bahnschrift; font-size: 150px; font-variation-settings: &apos;wght&apos; 400, &apos;wdth&apos; 100;">{{this.contract.name}}</text>
//    <text x="50%" y="6600" style="text-anchor:middle;text-align:center;fill: #333; font-family: Bahnschrift, Bahnschrift; font-size: 150px; font-variation-settings: &apos;wght&apos; 400, &apos;wdth&apos; 100;">#{{this.tokenId}}</text>
//    <foreignObject x="350" y="8000" width="2000" height="1000">
//        <p xmlns="http://www.w3.org/1999/xhtml" style="text-align:center; font-size:80px; line-height:100px; color:#000"> 
//            <span v-for="a in this.attributes" :key="a.trait_type">
//                <span style="font-weight:bold">{{a.trait_type}}</span>: 
//                <span style="color:#333;">{{a.value.length==0 ? 'None' : a.value}}</span> <br/>
//            </span>
//        </p>
//    </foreignObject>
//</g>
//        <image id="nftImage" x="-525" y="-75" width="3750" height="10050" xlink:href="https://www.boardpusher.com/design/decktemplates/8_25_mask_build_BP_standard_28.png" />
//    </svg>`
//))


//Templates.push(ProductTemplate.fromMarkup(
//    `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2700 9900" width="180" height="660">
//<title>Black Version</title>
//<desc>A fully black version with attributes listed</desc>
//  <g id="Layer_1" data-name="Layer 1">
//    <rect x="0" y="0" width="2700" height="9900" style="fill: #333;"/>
//    <image width="5000" height="5000" transform="translate(-161.95 3400) scale(.6)" :xlink:href="this.imageUrl"/>
//  </g>
//  <g id="Text_Layer" data-name="Text Layer">
//    <text x="50%" y="1500" style="text-anchor:middle;text-align:center;fill: #fff; font-family: Bahnschrift, Bahnschrift; font-size: 150px; font-variation-settings: &apos;wght&apos; 400, &apos;wdth&apos; 100;">{{this.contract.name}}</text>
//    <text x="50%" y="6600" style="text-anchor:middle;text-align:center;fill: #ccc; font-family: Bahnschrift, Bahnschrift; font-size: 150px; font-variation-settings: &apos;wght&apos; 400, &apos;wdth&apos; 100;">#{{this.tokenId}}</text>
//    <foreignObject x="350" y="8000" width="2000" height="1000">
//        <p xmlns="http://www.w3.org/1999/xhtml" style="text-align:center; font-size:80px; line-height:100px; color:#fff"> 
//            <span v-for="a in this.attributes" :key="a.trait_type">
//                <span style="font-weight:bold">{{a.trait_type}}</span>: 
//                <span style="color:#ccc;">{{a.value.length==0 ? 'None' : a.value}}</span> <br/>
//            </span>
//        </p>
//    </foreignObject>
//    <image id="nftImage" x="-525" y="-75" width="3750" height="10050" xlink:href="https://www.boardpusher.com/design/decktemplates/8_25_mask_build_BP_standard_28.png" />
 
//</g>
//</svg>`
//))

export default Templates;

export async function getTemplateData(url: string) {
    
    //const requestUrl = process.env.VUE_APP_NF_API + "get-template?u=" + url
    //console.log("api request", requestUrl)
    //const r = await fetch(requestUrl)



    url = url.replace("https://bondcarr.com", "")
    const r = await fetch(process.env.VUE_APP_NF_API + "get-template?u=" + encodeURIComponent(url))

    console.log("getting template data", r)

    return await r.text()
}

export async function getSvgImage(svgData: string): Promise<Blob> {
   
    const requestUrl = process.env.VUE_APP_NF_API + "get-preview"
    const r = await fetch(requestUrl, {
        method: 'POST',
        body: svgData,
        headers: {
            'action': 'ptch',
            'Content-Type': 'application/json'
        }
    });
    console.log("api response", r)
    return r.blob()
}

export async function getSvgImageUrl(svgData: string): Promise<string> {

    const requestUrl = process.env.VUE_APP_NF_API + "get-preview"
    const r = await fetch(requestUrl, {
        method: 'POST',
        body: svgData,
        headers: {
            'action': 'ptch',
            'Content-Type': 'application/json'
        }
    });
    console.log("api response", r)
    return r.text()
}

