import { Color, Fog, GridHelper, Mesh, MeshBasicMaterial, PlaneGeometry, Scene, ShadowMaterial } from 'three';

function createScene() {
    const scene = new Scene();
    //addShadowPlane(scene);
    //const gridHelper = new GridHelper(10, 10, 0xaec6cf, 0xaec6cf)
    //scene.add(gridHelper)
    //scene.fog = new Fog(0xffffff, 5, 100);
    return scene;
}

function addShadowPlane(scene) {

    const geometry = new PlaneGeometry(2, 2);
    const material = new ShadowMaterial();
    //const material = new MeshBasicMaterial();
    material.color = 0xeeeeee as unknown as Color;
    material.opacity = 0.1;

    const shadowPlane = new Mesh(geometry, material);
    shadowPlane.name = "shadowPlane";
    shadowPlane.rotateY(Math.PI);
    //shadowPlane.rotateZ(- Math.PI / 2);
    shadowPlane.receiveShadow = true;
    shadowPlane.position.z = 0.075;
    //shadowPlane.position.y = -1;

    console.log("ShadowPlane: ", shadowPlane);

    scene.add(shadowPlane);
}

export { createScene };